import React from "react"

import { Container, Row, Col } from 'reactstrap'
import Link from '../components/link'
import Button from '../components/btn'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from '../components/form'
import Slider from '../components/slider'
import Box from '../components/box'
import Hr from '../components/hr'
import { FaHome, FaDesktop, FaMobileAlt, FaChartLine, FaBong, FaBalanceScale } from 'react-icons/fa';
import {IoIosArrowDroprightCircle} from 'react-icons/io';
import { AiTwotoneExperiment } from 'react-icons/ai';
import { GiThink } from 'react-icons/gi';
import { GoDashboard } from 'react-icons/go';
import Clients from '../components/clients'
import styled from 'styled-components'
import PageTitle from '../components/page-title'
import Img from 'gatsby-image/withIEPolyfill'
import { FaCode } from 'react-icons/fa'

let StyledImg = styled(props => <Img {...props}/>)`
  perspective: 1500px;
  perspective-origin: left center;
  overflow: visible !important;
  picture, img {
    transform: rotateY(-35deg) rotateX(15deg);
    box-shadow: 25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2);
    border-radius: .625rem;
    transition: 1s !important;
    &:hover {
      transform: rotateY(-30deg) rotateX(15deg);
    }
  }
`

let StyledBackground = styled.div`
  background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
`

const Background = styled.div`
  background: #3a3fb8;
  color: white;
  padding: 5rem 0;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  text-align: center;
`

let ABTestImg = styled.img`
width: 100%;
max-width: 447px;
`

let Service = ({title, Icon = FaHome, link, details}) => (
  <div class="col-md-4">
    <Link to={link}>
      <Box>
        <Icon size={40}/>
        <h4 className="mt-3">{title}</h4>
        <p>{details}</p>
      </Box>
    </Link>
  </div>
)

let Tool = ({title, icon, content}) => (
  <div className="d-flex mb-5">
    <img src={icon} width="75px"/>
    <div className="ml-3">
      <h4>{title}</h4>
      <p className="m-0 text-muted">{content}</p>
    </div>
  </div>
)

export default () => (
  <Layout>
    <SEO title="AB Testing | Empathy | Data agency" description="Empathy help business make better digital presence with data. We brainstorm hypothesis &amp; test ideas with you to maximize your conversion rates." keywords="AB testing, multivariate testing, Google Optimize, Adobe Target, Optimizely, VWO"/>
    <Background>
      <h2>"I didn't think; I experimented."</h2>
      <p>Wilhelm Roentgen - physicist who discovered X-Ray</p>
    </Background>

    <Container className="pt-4">
      <div className="text-center">
        <h1>Website Optimization</h1>
        <p className="text-muted">Use experiment &amp; data to deliver what's best for your customers.</p>
      </div>
    </Container>
    <Container className="py-4">
          <Row className="d-flex align-items-center">
            <Col md="6">
              <div className="mb-4">
                <h2 className="text-primary">Our Approach</h2>
                <p>What's better than allowing your customers to decide what's best for them?</p>
                <p>To optimize your customers' online experiences, we take a scientific approach called <strong>AB Testing</strong>.</p>
                <p>AB Testing simply divide your website visitors into 2 groups, each group will see a different experience that you want to test. We monitor the behaviour of the 2 groups and find out which experience is more preferred and generate the best business outcomes.</p>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex mb-5">
                <ABTestImg src="/images/AB_testing.png" alt="AB Testing"/>
              </div>
            </Col>
          </Row>
        </Container>   
    <Container className="py-4 service">
      <h2 className="text-primary mb-4">What We Do</h2>
      <Row>
        <Service title="1. Idea Creation" details="Propose testing ideas &amp; and the reason behind" Icon={GiThink}/>
        <Service title="2. Test Implementation" details="Configure testing platform according to agreed test plan" Icon={AiTwotoneExperiment}/>
        <Service title="3. Test Evaluation" details="Evaluate test results and give recommendations" Icon={FaBalanceScale}/>
      </Row>
    </Container>   
    <Container className="py-4">
      <h2 className="text-primary mb-4">Platforms We Use</h2>
      <Row>
          <Col>
            <Tool title="Google Optimize" icon="/images/logo/googleoptimize.png" content="Free to use and part of Google Marketing Platform, tightly integrated with Google Analytics."/>  
            <Tool title="Adobe Target" icon="/images/logo/adobetarget.png" content="Part of Adobe Marketing Cloud, tightly integrated with Adobe Analytics."/>  
          </Col>
          <Col>
            <Tool title="Optimizely" icon="/images/logo/optimizely.png" content="Paid platform that supports easy AB test &amp; personalization setup."/>  
            <Tool title="VWO" icon="/images/logo/vwo.png" content="Paid platform that supports AB test together wth on-page surveys, heatmaps, etc."/>  
          </Col>
      </Row>
    </Container>
    <StyledBackground>
      <div className="py-5">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <Box style={{textAlign: 'left'}}>
                <h3 className="text-center">Contact Us</h3>
                <Hr/>
                <Form/>
              </Box>
            </Col>
          </Row>
        </Container>
      </div>
    </StyledBackground>
  </Layout>
)
